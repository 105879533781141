import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { authHeader } from './authHeader';
import { store } from '../redux/newReduxActions/newIndex';
import { startApiCall, stopApiCall } from '../redux/newReduxActions/newSettingsActions';

const DEFAULT_BASEURL = '';
const DEVELOPMENT_BASEURL = 'https://localhost:44360';
const isDevelopment = (window.location.hostname.toLowerCase() === 'localhost');

const httpClient = axios.create({ 
    headers: { 
        'X-Timezone-Offset': '' + new Date().getTimezoneOffset(),
    },
    baseURL: isDevelopment ? DEVELOPMENT_BASEURL : DEFAULT_BASEURL
});

httpClient.defaults.headers.post['Content-Type'] = 'application/json';

//==================================================================================== INTERCEPTORS


httpClient.interceptors.request.use(async function Request(config) {
    const controller = new AbortController();

    //console.log("Config used", config);

    store.dispatch(startApiCall());
    config.headers.Authorization = authHeader().Authorization;

    // await fetch((isDevelopment ? DEVELOPMENT_BASEURL : DEFAULT_BASEURL) + '/api/auth/pingServer',{
    //     method:'GET',
    // })	
    // .then(response => response.json())
	// .then(response => console.log(response))
	// .catch(err => console.error(err));
    
    return {...config, signal: controller.signal};
});

httpClient.interceptors.response.use(
    function Resp(response) {
        store.dispatch(stopApiCall());
        if (response.status === 200 && response.data && response.data.message) {
            toastr.success(response.data.message);
        }
        return response;
    },
    function Err(error) {
        store.dispatch(stopApiCall());

        const title = 'The action did not complete successfully';
        let message = '';

        if (error.response) {                                       // The server responded with an error
            if (error.config.responseType === 'blob') {             // trying to download a file
                if (error.response.status === 404) {
                    message = 'The file was not found';
                } else {
                    message = error.message;
                }
            } else if (error.response && error.response.data) {     // error details available from the server
                message = error.response.data.Message 
                    || error.response.data.message;
            } else {                                                // no error details available
                message = 'No information is available from the server';
            }

        } else if (error.request) {                                 // The server did not respond
            message = 'The server is not responding';

        } else {                                                    // Unable to send the request
            message = 'The request could not be sent to the server. '
                + error.message;
        }
        toastr.error( title, message, { timeOut: 10000 } );
        return Promise.reject(error);
    }
);

//======================================================================================= API CALLS
export const baseUrl = httpClient.defaults.baseURL;

//-------------------------------------------------------------------------------------- Deprecated
/*export const downloadCVUrl = httpClient.defaults.baseURL;

export const getDatas = (params) => {
    const data = {
        results: 10,
        ...params,
    };
    return httpClient.get('https://randomuser.me/api', data);
};

export const downloadCV = (id) => {
    return httpClient.get(`api/create-pdf/cv/${id}`);
};*/

//------------------------------------------------------------------------------------------- Asset

export const getAllAssets = () => {
    return httpClient.get('api/assets/all');
};

export const getAsset = (id) => {
    return httpClient.get(`api/assets/${id}`);
};

export const reserveAsset = (prefix) => {
    return httpClient.post(`api/assets/reserve/${prefix}`);
};

export const createAsset = (id, data) => {
    return httpClient.post(`api/assets/${id}`, data);
};

export const updateAsset = (id, data) => {
    return httpClient.put(`api/assets/${id}`, data);
};

export const deleteAsset = (id) => {
    return httpClient.delete(`api/assets/${id}`);
};

export const getReservationsCalendar = (year,month) => {
    return httpClient.get(`api/assets/reservations/calendar/${year}/${month}`);
};

export const getAllAssetReservations = (assetId) => {
    return httpClient.get(`api/assets/reservations/all/${assetId}`);
};

export const getAllThermometers = () => {
    return httpClient.get('api/assets/thermometers');
}
export const getAllVoltMeters = () => {
    return httpClient.get('api/assets/ampvoltmeters');
}
export const getAllWhiteLightMeters = () => {
    return httpClient.get('api/assets/whitelightmeters');
}

export const getAssetReservation = (id) => {
    return httpClient.get(`api/assets/reservations/${id}`);
};

export const createAssetReservation = (data) => {
    return httpClient.post('api/assets/reservations', data);
};

export const updateAssetReservation = (id, data) => {
    return httpClient.put(`api/assets/reservations/${id}`, data);
};

export const deleteAssetReservation = (id) => {
    return httpClient.delete(`api/assets/reservations/${id}`);
};

//--------------------------------------------------------------------------------------- Audit Log

export const getAuditLogs = (skip = 0, take = 1000) => {
    return httpClient.get('api/audit-logs', {params: {skip,take}  });
};

//---------------------------------------------------------------------------------- Authentication

export const login = (acc) => {
    return httpClient.post('api/auth/login', acc);
};

export const getCurrentUser = () => {
    return httpClient.get('api/auth/profile');
};

export const updateProfile = (profile) => {
    return httpClient.put('api/auth/profile', profile);
};

export const isValidEmail = (email) => {
    return httpClient.post('api/auth/forgot-password', email);
};

export const isValidCode = (code) => {
    return httpClient.post('api/auth/verify-code', code);
};

export const resendEmail = (email) => {
    return httpClient.post('api/auth/resend-email', email);
};

export const updatePassword = (data) => {
    // for interactive password changes from the profile page
    return httpClient.put('api/auth/password', data);
};

export const newPassword = (data) => {
    // for password changes using verify code sent to user
    return httpClient.post('api/auth/change-password', data);
};

export const getLoginControl = () => {
    return httpClient.get('api/auth/control');
}

//----------------------------------------------------------------------------------------- Clients

export const getClients = () => {
    return httpClient.get('api/clients/all');
};

export const getAllCertificationClients = () => {
    return httpClient.get('api/clients/allcertificationclients');
};

export const createClient = (data) => {
    return httpClient.post('api/clients', data);
};

export const getClient = (id) => {
    return httpClient.get(`api/clients/${id}`);
};

export const updateClient = (id, data) => {
    return httpClient.put(`api/clients/${id}`, data);
};

export const deleteClient = (id) => {
    return httpClient.delete(`api/clients/${id}`);
};

export const getClientContacts = (clientId) => {
    return httpClient.get(`api/clients/contacts/${clientId}`);
}

export const updateClientContacts = (clientId, data) => {
    return httpClient.put(`api/clients/contacts/${clientId}`, data);
};
//------------------------------------------------------------------------------------ Competencies

export const getListCompetencies = () => {
    return httpClient.get('api/competencies/all');
};

export const getDropDownCompetency = (currentId) => {
    if (currentId) {
        return httpClient.get(`api/competencies/competency-dropdown?currentId=${currentId}`);
    } else {
        return httpClient.get('api/competencies/competency-dropdown');
    }
};

export const getCompetency = (id) => {
    return httpClient.get(`api/competencies/${id}`);
};

export const addCompetency = (competency) => {
    return httpClient.post('api/competencies/', competency);
};

export const updateCompetency = (id, competency) => {
    return httpClient.put(`api/competencies/${id}`, competency);
};

export const deleteCompetency = (id) => {
    return httpClient.delete(`api/competencies/${id}`);
};

//------------------------------------------------------------------------------- Contractor Portal

export const getContractorPortalLoginMessage = () => {
    return httpClient.get('api/contractorportal/loginmessage');
}

export const getContractorPortalTimesheets = () => {
    return httpClient.get('api/contractorportal/timesheets');
}

export const getContractorPortalCalendar = () => {
    return httpClient.get('api/contractorportal/calendar');
}

//------------------------------------------------------------------------------------- Contractors

export const getContractors = (params) => {
    return httpClient.get('api/contractors/all', { params });
};

export const getContractorsForSearch = () => {
    return httpClient.get('api/contractors/allforsearch');
};

export const getRelationships = () => {
    return httpClient.get('api/contractors/get-relationships');
};

export const createContractor = (data) => {
    return httpClient.post('api/contractors', data);
};

export const getContractor = (id) => {
    return httpClient.get(`api/contractors/${id}`);
};

export const getUnlinkedUsers = () => {
    return httpClient.get(`api/users/unlinkedExaminers`);
};
export const putLinkUsers = (contractorId, userId) => {
    return httpClient.post(`api/users/linkExmainer/${contractorId}`, userId);
};
export const getContractorOwnData = () => {
    return httpClient.get('api/contractors/owndata');
};

export const updateContractor = (id, data) => {
    return httpClient.put(`api/contractors/${id}`, data);
};

export const deleteContractor = (id) => {
    return httpClient.delete(`api/contractors/${id}`);
};

export const getDuplicateContractors = () => {
    return httpClient.get('api/contractors/duplicates');
}

export const getUnapprovedContractors = () => {
    return httpClient.get('api/contractors/unapproved');
}

export const getMissingContractorFiles = () => {
    return httpClient.get('api/contractors/missingfiles');
}

export const sendContractorMissingReminder = (id, docTypes) => {
    const data = { contractorId: id, docTypes: docTypes }
    return httpClient.post('api/contractors/sendmissingemail', data);
}

export const getExpiringContractorFiles = () => {
    return httpClient.get('api/contractors/expiringfiles');
}

export const sendContractorExpiringReminder = (id, fileIds) => {
    const data = { contractorId: id, fileIds: fileIds }
    return httpClient.post('api/contractors/sendexpiryemail', data);
}

export const getCountryDisciplineReport = () => {
    return httpClient.get('api/contractors/countryreport');
}

export const getContractorCreationList = () => {
    return httpClient.get('api/contractors/creationlist');
}

export const getRatingReport = () => {
    return httpClient.get('api/contractors/ratingreport');
}
//-------------------------------------------------------------------------------- Initial Competency Assessment

export const getAllInitialCompetencyExams = () => {
    return httpClient.get(`api/competencyAssessment/GetInitialCompetencyChecklists`);
}

export const getInitialCompetencyChecklist = (id) => {
    return httpClient.get(`api/competencyAssessment/getChecklist/${id}`);
}

export const getSyncAllInitialCompetencyExams = () => {
    return httpClient.get(`api/competencyAssessment/SyncInitialCompetencyChecklists`);
}

export const updateInitialCompetencyChecklist = (data) => {
    return httpClient.post(`api/competencyAssessment/UpdateInitialCompetencyChecklists`, data);
}

export const syncCompetencyChecklistOntoReport = (data) => {
    return httpClient.put(`api/competencyAssessment/SyncInitialCompetencyChecklistsToReport`, data);
}

//-------------------------------------------------------------------------------- Competency Assessment

export const getAllCompetencyAssessment = () => {
    return httpClient.get(`api/competencyAssessment/getAllChecklists`);
}

export const getCompetencyAssessment = (id) => {
    return httpClient.get(`api/competencyAssessment/getChecklist/${id}`);
}

export const postCompetencyAssessment = (data) => {
    return httpClient.post(`api/competencyAssessment`, data);
}

export const getAllMostRecentAssessmentOptions = () => {
    return httpClient.get(`api/competencyAssessment/allMostRecentAssessments`);
}

export const getAssessmentInfoFromIds = (data) => {
    return httpClient.put(`api/competencyAssessment/getAssessmentsInfoFromIds`, data);
}
//-------------------------------------------------------------------------------- Dashboard Manage

export const getDashboardNotice = () => {
    return httpClient.get('api/dashboards');
};

export const updateDashboardNotice = (dashboard) => {
    return httpClient.put('api/dashboards', dashboard);
};

export const getJobsDashboard = () => {
    return httpClient.get('api/dashboards/jobs');
};

export const getLostJobs = (from, to, id=null) => {
    return httpClient.get('api/dashboards/lostJobs', {params:{from:from, to:to, coordinatorID:id}});
};

//-------------------------------------------------------------------------------------- Discipline

export const getAllDisciplines = () => {
    return httpClient.get('api/disciplines/all');
};

export const createDiscipline = (data) => {
    return httpClient.post('api/disciplines', data);
};

export const getDiscipline = (id) => {
    return httpClient.get(`api/disciplines/${id}`);
};

export const updateDiscipline = (id, data) => {
    return httpClient.put(`api/disciplines/${id}`, data);
};

export const deleteDiscipline = (id) => {
    return httpClient.delete(`api/disciplines/${id}`);
};

export const getDropDownDiscipline = () => {
    return httpClient.get('api/disciplines/discipline-dropdown');
};

//------------------------------------------------------------------------------------------- Document

export const getAllDocuments = () => {
    return httpClient.get('api/documentController/all');
}
export const getDocument = (id) => {
    return httpClient.get(`api/documentController/${id}`);
}

export const updateDocument = (data) => {
    return httpClient.post('api/documentController', data);
}

//------------------------------------------------------------------------------------------- Email

export const sendEmailMessage = (data, progressCallback) => {
    const config = (progressCallback) ? { onUploadProgress: progressCallback } : {};
    return httpClient.post('api/email/send', data, config);
};

export const getEmailList = (mailboxId) => {
    return httpClient.get(`api/email/list/${mailboxId}`);
}

export const getEmailDetail = (id) => {
    return httpClient.get(`api/email/${id}`);
}

export const getUnreadEmails = () => {
    return httpClient.get('api/email/unread');
}

export const getEmailLog = () => {
    return httpClient.get('api/email/log');
}

export const getEmailSysLog = () => {
    return httpClient.get('api/email/syslog');
}

export const markAllEmailsRead = () => {
    return httpClient.put('api/email/allread');
}

//------------------------------------------------------------------------------------------- Exams

export const getAllExams = () => {
    return httpClient.get('api/exam/all');
}
export const getExam = (id) => {
    return httpClient.get(`api/exam/${id}`);
}
export const updateExamApi = (data) => {
    return httpClient.put('api/exam', data);
}
export const postExamForRev = (data) => {
    return httpClient.post('api/exam/revexam', data);
}
export const deleteExam = (id) => {
    return httpClient.delete(`api/exam/${id}`);
}
export const getAllAttempts = () => {
    return httpClient.get(`api/exam/allattempts`);
}
export const getAttempt = (id) => {
    return httpClient.get(`api/exam/attempt/${id}`);
}
export const getExamForAttempt = (id) => {
    return httpClient.get(`api/exam/${id}/attempt`);
}
export const postAttemptedExam = (data) => {
    return httpClient.post('api/exam/attempt', data);
}
//------------------------------------------------------------------------------------------- Examiners

export const getAllExaminers = () => {
    return httpClient.get('api/necitexaminer/all')
}

export const getExaminer = (id) => {
    return httpClient.get(`api/necitexaminer/${id}`)
}

export const addNewExaminer = (data) => {
    return httpClient.post('api/necitexaminer', data)
}

export const updateExaminer = (id, data) => {
    return httpClient.put(`api/necitexaminer/${id}`, data)
}

export const deleteExaminer = (id) => {
    return httpClient.delete(`api/necitexaminer/${id}`)
}
export const generateAuthorisationsForExaminer = () => {
    return httpClient.get('api/necitexaminer/getmissingauthorisations');
}
export const refreshExaminerAuthorisations = (id) => {
    return httpClient.get(`api/necitexaminer/refreshAuthorisations/${id}`);
}

//------------------------------------------------------------------------------------------- Files

export const uploadFile = (file, progressCallback, filename) => {
    let data = new FormData();
    data.append('file', file, filename);
    const config = (progressCallback) ? 
    { 
        onUploadProgress: progressCallback,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } 
    : {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return httpClient.post('api/files', data, config);
};

export const legacyUploadFile = (file, filename) => {
    let data = new FormData();
    data.append('file', file, filename);
    const config =
    { 
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } 

    return httpClient.post('api/files', data, config);
};

export const downloadFile = (link, progressCallback) => {
    const config = {
        params: {link: link},
        responseType: 'blob',
    }
    if (progressCallback) config.onDownloadProgress = progressCallback;
    return httpClient.get('api/files', config);
}

export const downloadTemplate = (folder, templateName, progressCallback) => {
    const config = {
        params: {folder:folder, template:templateName},
        responseType: 'arraybuffer',
    }
    if (progressCallback) config.onDownloadProgress = progressCallback;
    return httpClient.get('api/files/template', config);
}

export const downloadImageFromAzureBlob = (link, progressCallback) => {
    const config = {
        params: {link: link},
        responseType: 'arraybuffer',
    }
    if (progressCallback) config.onDownloadProgress = progressCallback;
    return httpClient.get('api/files/imagefromazure', config);
}
export const downloadImageBlobFromAzureBlob = (link, progressCallback) => {
    const config = {
        params: {link: link},
        responseType: 'blob',
    }
    if (progressCallback) config.onDownloadProgress = progressCallback;
    return httpClient.get('api/files/imagefromazure', config);
}

export const uploadWorkflowDoc = (filename, link, progressCallback) => {
    const data = { filename:filename, link:link }
    const config = {};
    if (progressCallback) config.onUploadProgress = progressCallback;
    return httpClient.post('api/files/workflow', data, config);
}

export const getDocumentLibrary = () => {
    return httpClient.get('api/files/library');
}

export const getLabelNames = () => {
    return httpClient.get('api/files/labelnames');
}

export const createLabelGroup = (data) => {
    return httpClient.post('api/files/labelgroupname', data);
}

export const updateLabelGroup = (data) => {
    return httpClient.put('api/files/labelgroupname', data);
}

export const deleteLabelGroup = (value) => {
    return httpClient.delete(`api/files/labelgroupname/${value}`);
}

export const createLabelName = (data) => {
    return httpClient.post('api/files/labelname', data);
}

export const updateLabelName = (data) => {
    return httpClient.put('api/files/labelname', data);
}

export const deleteLabelName = (value) => {
    return httpClient.delete(`api/files/labelname/${value}`);
}

export const searchFiles = (data) => {
    return httpClient.put('api/files/search', data);
}

//------------------------------------------------------------------------------------- Job Reports

export const getAllJobReports = () => {
    return httpClient.get('api/jobreports/all');
}

export const getJobReport = (id) => {
    return httpClient.get(`api/jobreports/${id}`);
}

export const updateJobReport = (data) => {
    return httpClient.put('api/jobreports', data);
}

export const getJobDayReport = (jobId, date) => {
    const config = { params:{jobId:jobId, date:date} }
    return httpClient.get('api/jobreports/jobday', config);
}

//-------------------------------------------------------------------------------------------- Jobs

export const getJobs = (data) => {
    return httpClient.get('api/jobs/all', {
        params: data,
    });
};

export const createJob = (data) => {
    return httpClient.post('api/jobs', data);
};

export const getJob = (id) => {
    return httpClient.get(`api/jobs/${id}`);
};

export const updateJob = (id, data) => {
    return httpClient.put(`api/jobs/${id}`, data);
};

export const deleteJob = (id) => {
    return httpClient.delete(`api/jobs/${id}`);
};

export const getSearchDropDowns = () => {
    return httpClient.get('api/jobs/get-dropdowns');
};

export const getAcceptedExpenses = () => {
    return httpClient.get('api/jobs/accepted-expenses');
};

export const getNoContractorReport = () => {
    return httpClient.get('api/jobs/no-contractor');
};

export const getEnquiryQuoteKpiReport = (from, to, id=null) => {
    // from/to are dates in yyyy-mm-dd format
    return httpClient.get('api/jobs/enquiry-to-quote-kpi', {params:{from:from, to:to, coordinatorID:id}});
};

export const cloneJob = (jobId) => {
    return httpClient.put(`api/jobs/clone/${jobId}`);
}

export const getJobCalendar = (from, to) => {
    // from/to are dates in yyyy-mm-dd format
    return httpClient.get('api/jobs/calendar', {params:{start:from, finish:to}})
}

export const getScopeReport = () => {
    return httpClient.get('api/jobs/scopereport');
}

//----------------------------------------------------------------------------------------- Keyword

export const getCountriesKeywords = () => {
    return httpClient.get('api/keywords/countries');
};

export const getDisciplinesKeywords = () => {
    return httpClient.get('api/keywords/disciplines');
};

export const getServicesKeywords = () => {
    return httpClient.get('api/keywords/services');
};

export const getCompetenciesKeywords = () => {
    return httpClient.get('api/keywords/competencies');
};

export const getQualificationsKeywords = () => {
    return httpClient.get('api/keywords/qualifications');
};

export const getJobRequirementKeywords = () => {
    return httpClient.get('api/keywords/jobrequirements');
}

export const getRegionKeywords = () => {
    return httpClient.get('api/keywords/regions');
}

export const getCurrencyKeywords = () => {
    return httpClient.get('api/keywords/currencies');
}

export const GetFileGroupKeywords = () => {
    return httpClient.get('api/keywords/filegroups');
}

export const getLabelGroups = () => {
    return httpClient.get('api/keywords/filelabelgroups');
}

export const GetFileLabelKeywords = (mode) => {
    return httpClient.get(`api/keywords/filelabels/${mode}`);
}

export const getWeldKeywords = (group) => {
    return httpClient.get(`api/keywords/weld/${group}`);
}

export const createWeldKeyword = (data) => {
    return httpClient.post('api/keywords/weld', data);
}

export const updateWeldKeyword = (id,data) => {
    return httpClient.put(`api/keywords/weld/${id}`, data);
}

export const deleteWeldKeyword = (id) => {
    return httpClient.delete(`api/keywords/weld/${id}`);
}

//--------------------------------------------------------------------------- Options

export const getAuthorisationOptions = () => {
    return httpClient.get(`api/options/authorisationOptions`);
}

//--------------------------------------------------------------------------- Potential Contractors

export const getpotentialContractorsByJobId = (jobId) => {
    return httpClient.get(`api/potentialcontractor/${jobId}/all`);
};

export const getCurrentPotentialContractors = (jobId) => {
    return httpClient.get(`api/potentialcontractor/${jobId}/current-contractorPotential`);
};

export const getListContractors = (jobId) => {
    return httpClient.get(`api/potentialcontractor/contractors/${jobId}`);
};

export const getSpecificContractors = (jobId, params) => {
    return httpClient.get(`api/potentialcontractor/specific-contractors?jobId=${jobId}`, { params });
};

export const addContractorToPotential = (data) => {
    return httpClient.post('api/potentialcontractor', data);
};

export const deleteContractorFromPotential = (id) => {
    return httpClient.delete(`api/potentialcontractor/${id}`);
};

export const getPotentialContractor = (id) => {
    return httpClient.get(`api/potentialcontractor/${id}`);
};

export const checkPotentialContractor = (jobId, contractorId) => {
    return httpClient.get(`api/potentialcontractor/check/${jobId}/${contractorId}`);
}

export const updatePotentialContractor = (id, data) => {
    return httpClient.put(`api/potentialcontractor/${id}`, data);
};
export const updatePotentialContractorBools = (id, data) => {
    return httpClient.put(`api/potentialcontractor/updatebools/${id}`, data);
};

//----------------------------------------------------------------------------------------- Project

export const getAllProjects = () => {
    return httpClient.get('api/projects/all');
}

export const getOpenProjects = () => {
    return httpClient.get('api/projects/open');
}

export const getProject = (id) => {
    return httpClient.get(`api/projects/${id}`);
}

export const createProject = (clientId) => {
    return httpClient.post(`api/projects/${clientId}`);
}

export const updateProject = (data) => {
    return httpClient.put('api/projects', data);
}

export const deleteProject = (id) => {
    return httpClient.delete(`api/projects/${id}`);
}

//----------------------------------------------------------------------------------- Qualification

export const createQualification = (parentId) => {
    return httpClient.post(`api/qualifications/create/${parentId}`);
}

export const getQualification = (id) => {
    return httpClient.get(`api/qualifications/${id}`);
};

export const updateQualification = (id, qualification) => {
    return httpClient.put(`api/qualifications/${id}`, qualification);
};

export const moveQualification = (id, parentId, order) => {
    const data = {id:id, parentId:parentId, order:order}
    return httpClient.put('api/qualifications/move', data);
}

export const deleteQualification = (id) => {
    return httpClient.delete(`api/qualifications/${id}`);
};

//------------------------------------------------------------------------------------------ Region

export const getAllRegions = () => {
    return httpClient.get('api/regions/all');
};

export const createRegion = (data) => {
    return httpClient.post('api/regions', data);
};

export const getRegion = (id) => {
    return httpClient.get(`api/regions/${id}`);
};

export const updateRegion = (id, data) => {
    return httpClient.put(`api/regions/${id}`, data);
};

export const deleteRegion = (id) => {
    return httpClient.delete(`api/regions/${id}`);
};

//------------------------------------------------------------------------------------ Registration

export const getRegistrationScreening = (id) => {
    return httpClient.get(`api/registrations/screening/${id}`);
}

export const updateRegistrationScreening = (data) => {
    return httpClient.put('api/registrations/screening', data);
}

export const getInProgressRegistrations = () => {
    return httpClient.get('api/registrations/inprogress');
}

export const getUnsuccessfulRegistrations = () => {
    return httpClient.get('api/registrations/unsuccessful');
}

export const deleteRegistration = (id) => {
    return httpClient.delete(`api/registrations/${id}`);
}

export const getRegistration = (id) => {
    return httpClient.get(`api/registrations/${id}`);
}

export const updateRegistration = (id, data) => {
    return httpClient.put(`api/registrations/${id}`, data);
}

export const createBlacklist = (data) => {
    return httpClient.post('api/registrations/blacklist', data);
}

export const getRegistrationIdForUser = (userId) => {
    return httpClient.get(`api/registrations/user/${userId}`);
}

//------------------------------------------------------------------------------------------ Report

export const getMatrixReport = (downloadTracker) => {
    return httpClient.get('api/reports/matrix', {onDownloadProgress:downloadTracker});
}

export const getWorkCalendar = (since) => {
    return httpClient.get('api/reports/workcalendar', {params:{since:since}})
}

//-------------------------------------------------------------------------------------------- Role

export const getRoles = () => {
    return httpClient.get('api/roles/get-roles');
};

//---------------------------------------------------------------------------------- Service Orders

export const getAllServiceOrders = () => {
    return httpClient.get('api/serviceorders/all');
}

export const getServiceOrderArchive = () => {
    return httpClient.get('api/serviceorders/archive');
}

export const getServiceOrder = (id) => {
    return httpClient.get(`api/serviceorders/${id}`);
}

export const updateServiceOrder = (data) => {
    return httpClient.put('api/serviceorders', data);
}

export const getServiceOrderMergeData = (jobId, contractorId) => {
    return httpClient.get(`api/serviceorders/mergedata/${jobId}/${contractorId}`);
}

//---------------------------------------------------------------------------------------- Services

export const getListServices = () => {
    return httpClient.get('api/services/all');
};

export const getService = (id) => {
    return httpClient.get(`api/services/${id}`);
};

export const addService = (service) => {
    return httpClient.post('api/services/', service);
};

export const updateService = (id, service) => {
    return httpClient.put(`api/services/${id}`, service);
};

export const deleteService = (id) => {
    return httpClient.delete(`api/services/${id}`);
};

//--------------------------------------------------------------------------------------- Suppliers

export const getSuppliers = () => {
    return httpClient.get('api/suppliers/all');
};

export const getUkasSuppliers = () => {
    return httpClient.get('api/suppliers/allukassuppliers');
};

export const getNormalSuppliers = () => {
    return httpClient.get('api/suppliers/allnormalsuppliers');
};

export const getNdtSuppliers = () => {
    return httpClient.get('api/suppliers/handlendt');
};

export const getMechSuppliers = () => {
    return httpClient.get('api/suppliers/handlemech');
};

export const createSupplier = (data) => {
    return httpClient.post('api/suppliers', data);
};

export const getSupplier = (id) => {
    return httpClient.get(`api/suppliers/${id}`);
};

export const updateSupplier = (id, data) => {
    return httpClient.put(`api/suppliers/${id}`, data);
};

export const deleteSupplier = (id) => {
    return httpClient.delete(`api/suppliers/${id}`);
};

export const getMissingSupplierFiles = () => {
    return httpClient.get('api/suppliers/missingfiles');
}

export const sendSupplierMissingReminder = (id, docTypes) => {
    const data = { supplierId: id, docTypes: docTypes }
    return httpClient.post('api/suppliers/sendmissingemail', data);
}

export const getExpiringSupplierFiles = () => {
    return httpClient.get('api/suppliers/expiringfiles');
}

export const sendSupplierExpiringReminder = (id, fileIds) => {
    const data = { supplierId: id, fileIds: fileIds }
    return httpClient.post('api/suppliers/sendexpiryemail', data);
}

export const getUnapprovedSuppliers = () => {
    return httpClient.get('api/suppliers/unapproved');
}

export const getBusinessUnits = () => {
    return httpClient.get('api/suppliers/businesses');
}

export const updateBusinessUnitManager = (id, managerId) => {
    return httpClient.put(`api/suppliers/businesses/${id}/${managerId}`);
}

//-------------------------------------------------------------------------------------- Timesheets

export const getAllTimesheetTrackers = () => {
    return httpClient.get('api/timesheets/trackers/all');
}

export const getTimesheetTracker = (id) => {
    return httpClient.get(`api/timesheets/trackers/${id}`);
}

export const updateTimesheetTracker = (data) => {
    return httpClient.put('api/timesheets/trackers', data);
}

export const getRecentTimesheetEntries = () => {
    return httpClient.get('api/timesheets/recent');
}

export const getTimesheetEntry = (id) => {
    return httpClient.get(`api/timesheets/${id}`);
}

export const createTimesheetEntry = (data) => {
    return httpClient.post('api/timesheets', data);
}

export const updateTimesheetEntry = (data) => {
    return httpClient.put('api/timesheets', data);
}

export const updateTimesheetEntryMini = (data) => {
    return httpClient.put('api/timesheets/mini', data);
}

export const getPotentialTimesheets = () => {
    return httpClient.get('api/timesheets/potentials');
}

export const getTimesheetReport = (id) => {
    return httpClient.get(`api/timesheets/report/${id}`);
}

export const getAllContractorInvoices = () => {
    return httpClient.get('api/timesheets/invoices');
}

export const getContractorInvoices = (contractorId) => {
    return httpClient.get(`api/timesheets/invoices/${contractorId}`);
}

export const getOwnContractorInvoices = () => {
    return httpClient.get('api/timesheets/owninvoices');
}

export const getContractorInvoice = (id) => {
    return httpClient.get(`api/timesheets/invoice/${id}`);
}

export const createContractorInvoice = (data) => {
    return httpClient.post('api/timesheets/invoice', data);
}

export const updateContractorInvoice = (data) => {
    return httpClient.put('api/timesheets/invoice', data);
}

export const getContractorInvoiceContractors = () => {
    return httpClient.get('api/timesheets/newinvoice/contractors');
}

export const getContractorInvoiceScopes = (contractorId) => {
    return httpClient.get(`api/timesheets/newinvoice/scopes/${contractorId}`);
}

export const getContractorInvoiceTimesheets = (contractorId,year,month,currency) => {
    return httpClient.get(`api/timesheets/newinvoice/timesheets/${contractorId}/${year}/${month}/${currency}`);
}

export const getUnbilledTimesheets = () => {
    return httpClient.get('api/timesheets/billing/unbilled');
}

export const createClientBilling = (data) => {
    return httpClient.post('api/timesheets/billing', data);
}

export const updateClientBilling = (data) => {
    return httpClient.put('api/timesheets/billing', data);
}

export const getClientBilling = (id) => {
    return httpClient.get(`api/timesheets/billing/${id}`);
}

export const getAllClientBilling = () => {
    return httpClient.get('api/timesheets/billing/all');
}

export const deleteClientBilling = (id) => {
    return httpClient.delete(`api/timesheets/billing/${id}`);
}

export const getClientBillingReport = () => {
    return httpClient.get('api/timesheets/billing/report');
}

//--------------------------------------------------------------------------------- User Management

export const getListUsers = () => {
    return httpClient.get('api/users/all');
};

export const getUser = (id) => {
    return httpClient.get(`api/users/${id}`);
};

export const getAllWeldingExaminers = () => {
    return httpClient.get(`api/users/examiners`);
}

export const getRegions = () => {
    return httpClient.get('api/users/get-regions');
};

export const addUser = (user) => {
    return httpClient.post('api/users', user);
};

export const addContractorFromUser = (user) => {
    return httpClient.post('api/users/newcontractor', user);
};

export const updateUser = (id, user) => {
    return httpClient.put(`api/users/${id}`, user);
};

export const deleteUser = (id) => {
    return httpClient.delete(`api/users/${id}`);
};

export const getStatus = () => {
    return httpClient.get('api/users/user-status');
};

//------------------------------------------------------------------------------------------ Vendor

export const getVendors = () => {
    return httpClient.get('api/vendors/all');
};

export const createVendor = (data) => {
    return httpClient.post('api/vendors', data);
};

export const getVendor = (id) => {
    return httpClient.get(`api/vendors/${id}`);
};

export const updateVendor = (id, data) => {
    return httpClient.put(`api/vendors/${id}`, data);
};

export const deleteVendor = (id) => {
    return httpClient.delete(`api/vendors/${id}`);
};

//--------------------------------------------------------------------------------------- Weld Jobs
//==== Weld Job Presets
export const getPresetWeldjobs = () => {
    return httpClient.get('api/presetweldjob/all');
}

export const getPresetWeldJob = (id) => {
    return httpClient.get(`api/presetweldjob/${id}`);
}

export const postPresetWeldJob = (data) => {
    return httpClient.post('api/presetweldjob', data);
}

export const putPresetWeldJob = (data) => {
    return httpClient.put(`api/presetweldjob`, data);
}

export const getGeneratedJobFromPreset = (data) => {
    return httpClient.post(`api/presetweldjob/generatenewjobfrompreset`, data);
}
//==== Preset JobForm
export const getCertInfo = (id) => {
    return httpClient.get(`api/weldjobs/certInfo/${id}`);
}
export const getWpqrCertInfo = (id) => {
    return httpClient.get(`api/weldjobs/wpqrCertInfo/${id}`);
}

export const getPresetJobForms = () => {
    return httpClient.get('api/presetjobform/all');
}

export const getPresetJobForm = (id) => {
    return httpClient.get(`api/presetjobform/${id}`);
}

export const getPresetJobFormsByClientId = (clientId) => {
    return httpClient.get(`api/presetjobform/presetsbyclient/${clientId}`);
}

export const createPresetJobForm = (data) => {
    return httpClient.post(`api/presetjobform`, data);
}

export const updatePresetJobForm = (data) => {
    return httpClient.put(`api/presetjobform`, data);
}

export const deletePresetJobForm = (id) => {
    return httpClient.delete(`api/presetjobform/${id}`);
}

export const getAllClientsForLists = () => {
    return httpClient.get('api/presetjobform/clientlist')
}

export const clonePresetJobOrderForm = (id) => {
    return httpClient.get(`api/presetjobform/generateOrderFormFromPreset/${id}`)
}
// enquiries
export const getWeldEnquiries = () => {
    return httpClient.get('api/weldjobs/all');
};
export const getTestEnquiries = () => {
    return httpClient.get('api/weldjobs/alltesting');
};

export const getWeldEnquiry = (id) => {
    return httpClient.get(`api/weldjobs/${id}`);
};

export const createWeldEnquiry = (data) => {
    return httpClient.post('api/weldjobs', data);
};

export const updateWeldEnquiry = (id, data) => {
    return httpClient.put(`api/weldjobs/${id}`, data);
};

export const deleteWeldEnquiry = (id) => {
    return httpClient.delete(`api/weldjobs/${id}`);
};

export const generateTestsForJobForm = (data) => {
    return httpClient.post('api/weldjobs/generateTests', data);
}

// jobs
export const getAllWeldJobs = (from,to) => {
    const config = { params:{start:from, finish:to} }
    return httpClient.get('api/weldjobs/jobs/all', config);
};

export const getAllCalendarEvents = (from,to) => {
    const config = { params:{start:from, finish:to} }
    return httpClient.get('api/weldjobs/getCalendarEvents', config);
};
export const getAllBankHolidayEvents = () => {
    return httpClient.get('api/weldjobs/getBankHolidayEvents');
};

export const getWeldEnquiryJobs = (id) => {
    return httpClient.get(`api/weldjobs/jobs/all/${id}`);
};

export const getWeldJob = (id, userId) => {
    return httpClient.get(`api/weldjobs/jobs/${id}/${userId}`);
};

export const createWeldJob = (data) => {
    return httpClient.post(`api/weldjobs/jobs`, data);
}

export const updateWeldJob = (id, data) => {
    return httpClient.put(`api/weldjobs/jobs/${id}`, data);
};

export const updateTestResultsForJob = (data) => {
    return httpClient.put(`api/weldjobs/updateTestResults`, data);
};

export const addRetestCoupon = (data) => {
    return httpClient.put(`api/weldjobs/addretestcoupon`, data);
};

export const deleteWeldJob = (id) => {
    return httpClient.delete(`api/weldjobs/jobs/${id}`);
};

export const generateRetestCoupon = (data) => {
    return httpClient.post(`api/weldjobs/generateretest`, data);
}

// materials
export const getAllWeldMaterials = () => {
    return httpClient.get('api/weldjobs/materials/all');
};

export const getWeldMaterial = (id) => {
    return httpClient.get(`api/weldjobs/materials/${id}`);
};

export const createWeldMaterial = (data) => {
    return httpClient.post('api/weldjobs/materials', data);
};

export const updateWeldMaterial = (id, data) => {
    return httpClient.put(`api/weldjobs/materials/${id}`, data);
};

export const deleteWeldMaterial = (id) => {
    return httpClient.delete(`api/weldjobs/materials/${id}`);
};

// pWPS [disabled]
export const getAllPwps = () => {
    return httpClient.get('api/weldjobs/pwps/all');
};

export const getPwps = (id) => {
    return httpClient.get(`api/weldjobs/pwps/${id}`);
};

export const createPwps = (jobid, data) => {
    return httpClient.post(`api/weldjobs/pwps/${jobid}`, data);
};

export const updatePwps = (id, data) => {
    return httpClient.put(`api/weldjobs/pwps/${id}`, data);
};

export const deletePwps = (id) => {
    return httpClient.delete(`api/weldjobs/pwps/${id}`);
};

// others

export const getAllWeldTasks = () => {
    return httpClient.get('api/weldjobs/tasks');
}

export const getCertAuths = () => {
    return httpClient.get('api/weldjobs/certauth');
}

export const sendWeldEmail = (data) => {
    return httpClient.post('api/weldjobs/email', data);
}

export const getWeldReport = (from,to) => {
    const config = { params:{start:from, finish:to} }
    return httpClient.get('api/weldjobs/report', config);
};

export const getWeldTimesheets = (from,to) => {
    const config = { params:{} }
    if (!!from) config.params.start = from;
    if (!!to) config.params.finish = to;
    return httpClient.get('api/weldjobs/timesheets', config);
};

//---------------------------------------------------------------------------------------- Welders

export const getWelders = (params) => {
    return httpClient.get('api/welders/all', { params });
};

export const getWelder = (id) => {
    return httpClient.get(`api/welders/${id}`);
};

export const updateWelder = (id, data) => {
    return httpClient.put(`api/welders/${id}`, data);
};

export const createWelder = (data) => {
    return httpClient.post('api/welders', data);
};

//---------------------------------------------------------------------------------------- Workflow

export const getWorkflowNotifications = () => {
    return httpClient.get('api/workflow/tasks');
}

export const getWorkflowCandidates = () => {
    return httpClient.get('api/workflow/candidates');
}

export const getUsersByUserTask = (flag) => {
    return httpClient.get(`api/workflow/usertask/${flag}`)
}

export const getAllWorkflowTasks = () => {
    return httpClient.get('api/workflow/alltasks');
}

export const getAllWorkflowStaffTasks = () => {
    return httpClient.get('api/workflow/alltasksforstaff');
}
export const getUserWorkflowTasks = () => {
    return httpClient.get('api/workflow/tasks');
}

export const reassignTask = (taskId, assigneeId) => {
    const body = {taskId:taskId, assigneeId:assigneeId};
    return httpClient.put('api/workflow/reassign', body);
}

export const completeTask = (taskId) => {
    const body = {taskId:taskId};
    return httpClient.put('api/workflow/completetask', body);
}

export const cancelTask = (taskId) => {
    const body = {taskId:taskId};
    return httpClient.put('api/workflow/canceltask', body);
}

